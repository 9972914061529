/* eslint-disable react/jsx-curly-newline */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';

import locale from 'config/locale';
import {
  REQUIRED,
  LOCALITY,
  MONTH_YEAR,
  GREATER_THAN_ZERO,
  NO_ERROR,
  REQUIRED_FORMATTED_ADDRESS,
} from 'constants/validators';
import {
  FREQUENCY_OPTIONS,
  FOUND_PROPERTY_OPTIONS,
  PROPERTY_OWNED_OPTIONS,
  PROPERTY_OWNED,
  PROPERTY_PURCHASING,
  NOT_OWN_PROPERTY_TYPES,
  STATE_OPTIONS,
  ZONING_OPTIONS,
  propertyTypeOptions,
  PROPERTY_STATUS_OPTIONS,
  PROPERTY_PRIMARY_PURPOSE_OPTIONS,
  LENDER_OPTIONS,
} from 'constants/options';
import { WEEKLY, MONTHLY } from 'shared/constants/options';
import {
  LIABILITY_TERM_LOAN,
  LIABILITY_OTHER_CATEGORY,
} from 'shared/constants/myCRMTypes/liabilities';
import {
  INCOME_RENTAL,
  INCOME_RENTAL_CATEGORY,
} from 'shared/constants/myCRMTypes/incomes';
import {
  PRIMARY_PURPOSE_INVESTMENT,
  TRANSACTION_CONSTRUCTIONS,
} from 'shared/constants/myCRMTypes/property';
import {
  ADDRESS_TYPE_CURRENT,
  ADDRESS_OWNERSHIP_TYPE_OWN_HOME,
  ADDRESS_OWNERSHIP_TYPE_OWN_HOME_MORTGAGE,
} from 'shared/constants/myCRMTypes/address';

import findLocality from 'services/localitiesApi';
import { getLoanTerms, getLoanTermMonthly } from 'services/optionsApi';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';

import Form from 'components/Form/Form';
import Question from 'components/Question/Question';
import AutocompleteInput from 'components/AutocompleteInput/AutocompleteInput';
import AutocompleteAddress from 'components/AutocompleteInput/AutocompleteAddress';
import Selection from 'components/Selection/Selection';
import Button from 'components/Button/Button';
import DateSelector from 'components/DateSelector/DateSelector';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import CurrencyInputWithSelection from 'components/CurrencyInputWithSelection/CurrencyInputWithSelection';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import YesNoButtonList from 'components/ButtonList/YesNoButtonList';
import Questions from 'lib/Questions';
import { featureFlags } from 'lib/rollout';
import { getPropertyOwnershipTypeId } from 'lib/propertyHelper';
import InputWithSpan from 'components/InputWithSpan/InputWithSpan';

const messages = defineMessages({
  ownedRadio: {
    id: 'PropertyForm.ownedRadio',
    defaultMessage: 'Do you own, rent, board, or live with your parents?',
  },
  ownedSelection: {
    id: 'PropertyForm.ownedSelection',
    defaultMessage: 'Ownership',
  },
  primaryPurposeIdRadio: {
    id: 'PropertyForm.primaryPurposeIdRadio',
    defaultMessage: 'What will this property be used for?',
  },
  primaryPurposeIdSelection: {
    id: 'PropertyForm.primaryPurposeIdSelection',
    defaultMessage: 'Property use',
  },
  foundPropertyRadio: {
    id: 'PropertyForm.foundPropertyRadio',
    defaultMessage: 'Have you found a property you want to buy?',
  },
  foundPropertySelection: {
    id: 'PropertyForm.foundPropertySelection',
    defaultMessage: 'Property found',
  },
  clientIdsRadio: {
    id: 'PropertyForm.clientIdsRadio',
    defaultMessage:
      'Who owns this {residence, select, true {home} false {property}}?',
  },
  clientIdsSelection: {
    id: 'PropertyForm.clientIdsSelection',
    defaultMessage: 'Property owned by',
  },
  zoningId: {
    id: 'PropertyForm.zoningId',
    defaultMessage: 'What type of property?',
  },
  statusId: {
    id: 'PropertyForm.statusId',
    defaultMessage: 'What kind of property?',
  },
  propertyTypeId: {
    id: 'PropertyForm.propertyTypeId',
    defaultMessage: 'Detailed type',
  },
  state: {
    id: 'PropertyForm.state',
    defaultMessage: 'What state will the property be in?',
  },
  address: {
    id: 'PropertyForm.address',
    defaultMessage: 'Address',
  },
  locality: {
    id: 'PropertyForm.locality',
    defaultMessage: 'What suburb is the property located in?',
  },
  startDate: {
    id: 'PropertyForm.startDate',
    defaultMessage: 'When did you move in?',
  },
  value: {
    id: 'PropertyForm.value',
    defaultMessage:
      '{purchasing, select, true {How much do you expect the property to cost?} false {Estimated value of property}}',
  },
  futureValue: {
    id: 'PropertyForm.futureValue',
    defaultMessage:
      'How much will the property be worth when building work is finished?',
  },
  existingMortgages: {
    id: 'PropertyForm.existingMortgages',
    defaultMessage: 'Are you paying off a mortgage?',
  },
  creditorId: {
    id: 'PropertyForm.creditorId',
    defaultMessage: 'Which lender are you with?',
  },
  totalLoanAmount: {
    id: 'PropertyForm.totalLoanAmount',
    defaultMessage: 'Current balance owing / outstanding',
  },
  originalLoanAmount: {
    id: 'PropertyForm.originalLoanAmount',
    defaultMessage: 'Loan limit',
  },
  currentInterestRate: {
    id: 'PropertyForm.currentInterestRate',
    defaultMessage: 'Current interest rate',
  },
  repaymentAmount: {
    id: 'PropertyForm.repaymentAmount',
    defaultMessage: 'Repayment Amount',
  },
  loanExpiryDate: {
    id: 'PropertyForm.loanExpiryDate',
    defaultMessage: 'Loan Expiry Date',
  },
  generateRentalIncome: {
    id: 'PropertyForm.generateRentalIncome',
    defaultMessage:
      '{purchasing, select, true {Will} false {Does}} this property generate rental income?',
  },
  useAsSecurity: {
    id: 'PropertyForm.useAsSecurity',
    defaultMessage: 'Will you be using this property as security?',
  },
  rentalIncomeAmount: {
    id: 'PropertyForm.rentalIncomeAmount',
    defaultMessage: 'Rental income',
  },
  isRefinance: {
    id: 'PropertyForm.isRefinance',
    defaultMessage: 'Are you going to refinance this mortgage?',
  },
  isInterestTaxDeductible: {
    id: 'PropertyForm.isInterestTaxDeductible',
    defaultMessage: 'Is interest tax deductible?',
  },
  save: {
    id: 'PropertyForm.save',
    defaultMessage: 'Save',
  },
  remove: {
    id: 'PropertyForm.remove',
    defaultMessage: 'Remove',
  },
});

const propsTransformForQuestion = ({
  working,
  workingAddress,
  workingLiability,
  workingIncome,
  clientIdOwnershipOptions,
}) => {
  const addressSource =
    working.owned === PROPERTY_OWNED ? working : workingAddress;

  return {
    ...working,
    address: addressSource && addressSource.address,
    startDate: workingAddress && workingAddress.startDate,
    ..._.omit(workingLiability, ['clientIds', 'errors']),
    rentalIncomeAmount: workingIncome.value,
    rentalIncomeFrequency: workingIncome.frequency,
    clientIdOwnershipOptions,
  };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const questionSet = (property) => {
  const questions = new Questions();
  const { owned: propertyOwnership } = property;

  if (property.residence) {
    questions.addBranch(['owned', REQUIRED]);
  }
  if (property.owned === PROPERTY_PURCHASING) {
    questions.addBranch(['primaryPurposeId', REQUIRED]);
    if (locale.isAU) {
      questions.addBranch(['foundProperty', REQUIRED]);
    }
    if (locale.isNZ) {
      questions.addBranch(
        ['clientIds', REQUIRED],
        ['statusId', REQUIRED],
        ['zoningId', REQUIRED],
        ['propertyTypeId', REQUIRED],
      );
    } else {
      questions.addBranch(
        ['statusId', REQUIRED],
        ['zoningId', REQUIRED],
        ['propertyTypeId', REQUIRED],
      );
    }
    if (property.foundProperty === 'true' || !locale.isAU) {
      questions.add(['locality', LOCALITY]);
    } else {
      questions.add(['state', REQUIRED]);
    }
    questions.add(['value', REQUIRED, GREATER_THAN_ZERO]);
    const isPurchasingConstruction =
      featureFlags.constructionLoanApps.isEnabled() &&
      TRANSACTION_CONSTRUCTIONS.includes(property.transactionId);
    if (isPurchasingConstruction) {
      questions.add(['futureValue']);
    }
    if (property.primaryPurposeId === PRIMARY_PURPOSE_INVESTMENT.id) {
      questions.add(['generateRentalIncome', REQUIRED]);
    }
    if (property.generateRentalIncome) {
      questions.add(['rentalIncomeAmount', REQUIRED]);
    }
  } else if (property.owned === PROPERTY_OWNED) {
    if (property.clientIdOwnershipOptions.length > 1) {
      questions.addBranchOrAdd(['clientIds', REQUIRED]);
    }
    questions.addBranch(
      ['zoningId', REQUIRED],
      ['propertyTypeId', REQUIRED],
      ['address', REQUIRED_FORMATTED_ADDRESS],
    );
    if (property.residence) {
      questions.add(['startDate', MONTH_YEAR]);
    }
    questions.add(
      ['value', REQUIRED, GREATER_THAN_ZERO],
      ['existingMortgages', REQUIRED],
    );
    const isDomesticAddress =
      !featureFlags.internationalAddresses.isEnabled() ||
      (property.address &&
        property.address.countryCode === locale.data.countryCode);
    const isDocumentedLoanTermEnabled = featureFlags[
      'consumer.documented-loan-term'
    ].isEnabled();
    if (property.existingMortgages) {
      questions.add(
        ['creditorId', REQUIRED],
        ['totalLoanAmount', REQUIRED, GREATER_THAN_ZERO],
        ['originalLoanAmount', REQUIRED, GREATER_THAN_ZERO],
        ['currentInterestRate'],
        ['repaymentAmount', REQUIRED, GREATER_THAN_ZERO],
      );
      if (isDocumentedLoanTermEnabled) {
        questions.add(['loanExpiryDate']);
      }
      if (isDomesticAddress) {
        questions.add(['isRefinance', REQUIRED]);
      }
      questions.add(['isInterestTaxDeductible', REQUIRED]);
    }
    if (!property.residence) {
      questions.add(['generateRentalIncome', REQUIRED]);
    }
    if (property.generateRentalIncome) {
      questions.add(['rentalIncomeAmount', REQUIRED]);
    }
    if (!property.isRefinance && isDomesticAddress) {
      questions.add(['useAsSecurity', REQUIRED]);
    }
  } else if (NOT_OWN_PROPERTY_TYPES.includes(property.owned)) {
    questions.addBranch(
      ['address', REQUIRED_FORMATTED_ADDRESS],
      ['startDate', MONTH_YEAR],
    );
  }
  if (propertyOwnership) {
    questions.add('saveButton');
  }
  return questions.arrayOfQuestions();
};

export class DisconnectedPropertyForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    working: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
    remove: PropTypes.func,
    setApplyPropertyClientIds: PropTypes.func.isRequired,
    setApplyPropertyPrimaryPurposeId: PropTypes.func.isRequired,
    setApplyPropertyOwned: PropTypes.func.isRequired,
    setApplyPropertyZoningId: PropTypes.func.isRequired,
    setApplyPropertyStatusId: PropTypes.func.isRequired,
    setApplyPropertyPropertyTypeId: PropTypes.func.isRequired,
    setApplyPropertyState: PropTypes.func.isRequired,
    setApplyPropertyAddress: PropTypes.func.isRequired,
    setApplyPropertyLocality: PropTypes.func.isRequired,
    setAddressTypeId: PropTypes.func.isRequired,
    setAddressOwnershipTypeId: PropTypes.func.isRequired,
    setAddressStartDateYear: PropTypes.func.isRequired,
    setAddressStartDateMonth: PropTypes.func.isRequired,
    setAddressAddress: PropTypes.func.isRequired,
    setAddressClientIds: PropTypes.func.isRequired,
    setPropertyIntlAddress: PropTypes.func.isRequired,
    setApplyPropertyValue: PropTypes.func.isRequired,
    setApplyPropertyFutureValue: PropTypes.func.isRequired,
    setApplyPropertyExistingMortgages: PropTypes.func.isRequired,
    setApplyPropertyUseAsSecurity: PropTypes.func.isRequired,
    setApplyPropertyGenerateRentalIncome: PropTypes.func.isRequired,
    setApplyPropertyError: PropTypes.func.isRequired,
    setApplyPropertyFoundProperty: PropTypes.func.isRequired,
    setIncomeValue: PropTypes.func.isRequired,
    setIncomeFrequency: PropTypes.func.isRequired,
    setIncomeType: PropTypes.func.isRequired,
    setIncomeCategory: PropTypes.func.isRequired,
    setLiabilityCreditorId: PropTypes.func.isRequired,
    setLiabilityTotalLoanAmount: PropTypes.func.isRequired,
    setLiabilityOriginalLoanAmount: PropTypes.func.isRequired,
    setLiabilityCurrentInterestRate: PropTypes.func.isRequired,
    setLiabilityRepaymentAmount: PropTypes.func.isRequired,
    setLiabilityLoanExpiryDateMonth: PropTypes.func.isRequired,
    setLiabilityLoanExpiryDateYear: PropTypes.func.isRequired,
    setLiabilityRepaymentFrequency: PropTypes.func.isRequired,
    setLiabilityIsInterestTaxDeductible: PropTypes.func.isRequired,
    setLiabilityClientIds: PropTypes.func.isRequired,
    setLiabilityType: PropTypes.func.isRequired,
    setLiabilityCategory: PropTypes.func.isRequired,
    workingLiability: PropTypes.object.isRequired,
    workingAddress: PropTypes.object,
    clientIdOwnershipOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLocked: PropTypes.bool,
    workingIncome: PropTypes.object.isRequired,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = questionSet;
  static revealMethod = 'chunks';
  static revealOverBranch = false;

  constructor(props) {
    super(props);
    this.state = {
      yearsData: [],
      monthsData: [],
    };
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  componentDidMount() {
    const {
      working,
      setApplyPropertyClientIds,
      workingIncome,
      workingLiability,
      setApplyPropertyFoundProperty,
      setApplyPropertyGenerateRentalIncome,
      setIncomeType,
      setIncomeCategory,
      setAddressTypeId,
      workingAddress,
      setAddressAddress,
      setAddressClientIds,
      setLiabilityClientIds,
      setLiabilityType,
      setLiabilityCategory,
      clientIdOwnershipOptions,
    } = this.props;

    getLoanTerms(false).then((data) => {
      const noYears = { name: '0 Years', value: 0 };
      this.setState({
        yearsData: [
          noYears,
          ...data.map((item) => ({ name: item.Name, value: item.Value })),
        ],
      });
    });

    getLoanTermMonthly().then((data) => {
      const noMonths = { name: '0 Months', value: 0 };
      this.setState({
        monthsData: [
          noMonths,
          ...data.map((item) => ({ name: item.Name, value: item.Value })),
        ],
      });
    });

    const firstClientId = (clientIdOwnershipOptions[0] || {}).value;

    if (
      !working.clientIds &&
      (clientIdOwnershipOptions.length === 1 || locale.isAU)
    ) {
      setApplyPropertyClientIds(firstClientId);
      setLiabilityClientIds(firstClientId);
    }
    if (working.owned === PROPERTY_PURCHASING && working.id) {
      setApplyPropertyFoundProperty((!!working.suburb).toString());
      setApplyPropertyGenerateRentalIncome(!!workingIncome.id);
    }
    if (!workingLiability.type) {
      setLiabilityType(LIABILITY_TERM_LOAN.id);
    }
    if (!workingLiability.category) {
      setLiabilityCategory(LIABILITY_OTHER_CATEGORY.id);
    }
    if (!workingIncome.type) {
      setIncomeType(INCOME_RENTAL);
    }
    if (!workingIncome.category) {
      setIncomeCategory(INCOME_RENTAL_CATEGORY);
    }
    if (workingAddress) {
      if (!workingAddress.typeId) {
        setAddressTypeId(ADDRESS_TYPE_CURRENT.id);
      }
      if (!workingAddress.clientIds) {
        setAddressClientIds(firstClientId);
      }
      if (
        working.address &&
        working.address.formattedAddress &&
        !workingAddress.address.formattedAddress
      ) {
        setAddressAddress(working.address.formattedAddress);
      }
    }
  }

  onSetOwnedClick = (value) => {
    const {
      working,
      setApplyPropertyOwned,
      setAddressOwnershipTypeId,
    } = this.props;

    setApplyPropertyOwned(value);
    if (working.residence && value) {
      setAddressOwnershipTypeId(getPropertyOwnershipTypeId(value));
    }
  };

  onSetAddress = (owned) => (value) => {
    const {
      setApplyPropertyAddress,
      setAddressAddress,
      workingAddress,
    } = this.props;
    if (owned) {
      setApplyPropertyAddress(value);
    }
    if (workingAddress) {
      setAddressAddress(value);
    }
  };

  onSetIntlAddress = (owned) => (value) => {
    const {
      setPropertyIntlAddress,
      setIntlAddress,
      setLiabilityIsRefinance,
      setApplyPropertyUseAsSecurity,
      workingAddress,
    } = this.props;
    if (owned) {
      setPropertyIntlAddress(value);
    }
    if (workingAddress) {
      setIntlAddress({ ...value, id: workingAddress.id });
    }
    if (value && value.countryCode !== locale.data.countryCode) {
      setLiabilityIsRefinance(undefined);
      setApplyPropertyUseAsSecurity(false);
    }
  };

  onExistingMortgagesClick = (value) => {
    const {
      working,
      setApplyPropertyExistingMortgages,
      setAddressOwnershipTypeId,
    } = this.props;

    setApplyPropertyExistingMortgages(value);
    if (working.residence) {
      const ownershipType = value
        ? ADDRESS_OWNERSHIP_TYPE_OWN_HOME_MORTGAGE
        : ADDRESS_OWNERSHIP_TYPE_OWN_HOME;
      setAddressOwnershipTypeId(ownershipType.id);
    }
  };

  onPropertyIsRefinanceClick = (value) => {
    this.props.setLiabilityIsRefinance(value);
    const useAsSecurity = value || undefined;
    this.props.setApplyPropertyUseAsSecurity(useAsSecurity);
  };

  onSubmit = () => {
    const { save, working } = this.props;
    save(working);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const {
      working,
      questions,
      formCompleted,
      isLocked,
      setCurrentQuestionTo,
      intl,
      setApplyPropertyError,
      setApplyPropertyClientIds,
      setApplyPropertyPrimaryPurposeId,
      setApplyPropertyZoningId,
      setApplyPropertyStatusId,
      setApplyPropertyPropertyTypeId,
      workingAddress,
      setApplyPropertyState,
      setApplyPropertyLocality,
      setAddressStartDateYear,
      setAddressStartDateMonth,
      setApplyPropertyValue,
      setApplyPropertyFutureValue,
      setApplyPropertyFoundProperty,
      setLiabilityIsInterestTaxDeductible,
      setApplyPropertyGenerateRentalIncome,
      setApplyPropertyUseAsSecurity,
      workingIncome,
      setIncomeValue,
      setIncomeFrequency,
      workingLiability,
      setLiabilityCreditorId,
      setLiabilityTotalLoanAmount,
      setLiabilityOriginalLoanAmount,
      setLiabilityCurrentInterestRate,
      setLiabilityRepaymentAmount,
      setLiabilityLoanExpiryDateMonth,
      setLiabilityLoanExpiryDateYear,
      setLiabilityRepaymentFrequency,
      remove,
      clientIdOwnershipOptions,
    } = this.props;
    const purchasing = working.owned === PROPERTY_PURCHASING;
    const owned = working.owned === PROPERTY_OWNED;
    const addressSource = owned ? working : workingAddress;
    const { yearsData, monthsData } = this.state;

    return (
      <Form
        id='propertyForm'
        formCompleted={formCompleted}
        onSubmit={this.onSubmit}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        <fieldset disabled={isLocked}>
          {!working.owned ? (
            <Question
              {...questions.owned}
              label={intl.formatMessage(messages.ownedRadio)}
              className='fullWidthInput'
            >
              <RadioButtonList
                action={this.onSetOwnedClick}
                value={undefined}
                items={PROPERTY_OWNED_OPTIONS(intl.formatMessage)}
              />
            </Question>
          ) : (
            <Question
              {...questions.owned}
              label={intl.formatMessage(messages.ownedSelection)}
              className='fullWidthInput'
            >
              <Selection
                action={this.onSetOwnedClick}
                value={working.owned}
                items={PROPERTY_OWNED_OPTIONS(intl.formatMessage)}
              />
            </Question>
          )}
          {!working.primaryPurposeId ? (
            <Question
              {...questions.primaryPurposeId}
              label={intl.formatMessage(messages.primaryPurposeIdRadio)}
              className='fullWidthInput'
            >
              <RadioButtonList
                action={setApplyPropertyPrimaryPurposeId}
                value={undefined}
                items={PROPERTY_PRIMARY_PURPOSE_OPTIONS}
              />
            </Question>
          ) : (
            <Question
              {...questions.primaryPurposeId}
              label={intl.formatMessage(messages.primaryPurposeIdSelection)}
              className='fullWidthInput'
            >
              <Selection
                action={setApplyPropertyPrimaryPurposeId}
                value={working.primaryPurposeId}
                items={PROPERTY_PRIMARY_PURPOSE_OPTIONS}
              />
            </Question>
          )}
          {!working.foundProperty ? (
            <Question
              {...questions.foundProperty}
              label={intl.formatMessage(messages.foundPropertyRadio)}
              className='fullWidthInput'
            >
              <RadioButtonList
                action={setApplyPropertyFoundProperty}
                value={undefined}
                items={FOUND_PROPERTY_OPTIONS}
              />
            </Question>
          ) : (
            <Question
              {...questions.foundProperty}
              label={intl.formatMessage(messages.foundPropertySelection)}
              className='fullWidthInput'
            >
              <Selection
                action={setApplyPropertyFoundProperty}
                value={working.foundProperty}
                items={FOUND_PROPERTY_OPTIONS}
              />
            </Question>
          )}
          {!working.clientIds ? (
            <Question
              {...questions.clientIds}
              label={intl.formatMessage(messages.clientIdsRadio, working)}
              className='fullWidthInput'
            >
              <RadioButtonList
                action={setApplyPropertyClientIds}
                value={undefined}
                items={clientIdOwnershipOptions}
              />
            </Question>
          ) : (
            <Question
              {...questions.clientIds}
              label={intl.formatMessage(messages.clientIdsSelection)}
              className='fullWidthInput'
            >
              <Selection
                action={setApplyPropertyClientIds}
                value={working.clientIds.join()}
                items={clientIdOwnershipOptions}
              />
            </Question>
          )}
          <Question
            {...questions.statusId}
            label={intl.formatMessage(messages.statusId)}
            className='fullWidthInput'
          >
            <Selection
              action={setApplyPropertyStatusId}
              value={working.statusId}
              items={PROPERTY_STATUS_OPTIONS}
            />
          </Question>
          <Question
            {...questions.zoningId}
            label={intl.formatMessage(messages.zoningId)}
            className='fullWidthInput'
          >
            <Selection
              action={setApplyPropertyZoningId}
              value={working.zoningId}
              items={ZONING_OPTIONS}
            />
          </Question>
          <Question
            {...questions.propertyTypeId}
            label={intl.formatMessage(messages.propertyTypeId)}
            className='fullWidthInput'
            visibility={!!working.zoningId}
          >
            <Selection
              action={setApplyPropertyPropertyTypeId}
              value={working.propertyTypeId}
              items={
                working.zoningId ? propertyTypeOptions(working.zoningId) : []
              }
            />
          </Question>
          <Question
            {...questions.address}
            label={intl.formatMessage(messages.address)}
            className='fullWidthInput'
          >
            <AutocompleteAddress
              setError={setApplyPropertyError}
              errorId='propertyAddress'
              action={this.onSetAddress(owned)}
              value={addressSource && addressSource.address.formattedAddress}
              intlValue={addressSource && addressSource.address}
              addressId={(addressSource && addressSource.id) || 'new'}
              intlOnChange={this.onSetIntlAddress(owned)}
              disabled={isLocked}
            />
          </Question>
          <Question
            {...questions.state}
            label={intl.formatMessage(messages.state)}
            className='fullWidthInput'
          >
            <Selection
              action={setApplyPropertyState}
              value={working.state}
              items={STATE_OPTIONS}
            />
          </Question>
          <Question
            {...questions.locality}
            label={intl.formatMessage(messages.locality)}
            hint={locale.isAU ? 'Enter a Suburb or Postcode' : 'Enter a Suburb'}
            className='fullWidthInput'
          >
            <AutocompleteInput
              setAsyncError={(text) =>
                setApplyPropertyError({ id: 'locality', text, blocking: true })
              }
              removeAsyncError={() =>
                setApplyPropertyError({ id: 'locality', text: NO_ERROR })
              }
              action={setApplyPropertyLocality}
              value={working.locality}
              serviceFn={findLocality}
            />
          </Question>
          <Question
            {...questions.startDate}
            label={intl.formatMessage(messages.startDate)}
            className='fullWidthInput'
          >
            <DateSelector
              type='month'
              monthAction={setAddressStartDateMonth}
              month={workingAddress && workingAddress.startDate.month}
              yearAction={setAddressStartDateYear}
              year={workingAddress && workingAddress.startDate.year}
              maxDate={moment()}
              yearsOrder='descending'
            />
          </Question>
          <Question
            {...questions.value}
            label={intl.formatMessage(messages.value, { purchasing })}
            className='fullWidthInput'
          >
            <CurrencyInput
              action={setApplyPropertyValue}
              value={working.value}
              maxLength={11}
            />
          </Question>
          <Question
            {...questions.futureValue}
            label={intl.formatMessage(messages.futureValue)}
            className='fullWidthInput'
          >
            <CurrencyInput
              action={setApplyPropertyFutureValue}
              value={working.futureValue}
            />
          </Question>
          <Question
            {...questions.existingMortgages}
            label={intl.formatMessage(messages.existingMortgages)}
            className='fullWidthInput'
            direction='row'
          >
            <YesNoButtonList
              action={this.onExistingMortgagesClick}
              value={working.existingMortgages}
            />
          </Question>
          <Question
            {...questions.creditorId}
            label={intl.formatMessage(messages.creditorId)}
            className='fullWidthInput'
          >
            <Selection
              action={setLiabilityCreditorId}
              value={workingLiability.creditorId}
              items={LENDER_OPTIONS()}
              disabled={workingLiability.isMultiple}
            />
          </Question>
          <Question
            {...questions.totalLoanAmount}
            label={intl.formatMessage(messages.totalLoanAmount)}
            className='fullWidthInput'
          >
            <CurrencyInput
              action={setLiabilityTotalLoanAmount}
              value={workingLiability.totalLoanAmount}
              maxLength={11}
              disabled={workingLiability.isMultiple}
            />
          </Question>
          <Question
            {...questions.originalLoanAmount}
            label={intl.formatMessage(messages.originalLoanAmount)}
            className='fullWidthInput'
          >
            <CurrencyInput
              action={setLiabilityOriginalLoanAmount}
              value={workingLiability.originalLoanAmount}
              maxLength={11}
              disabled={workingLiability.isMultiple}
            />
          </Question>
          <Question
            {...questions.currentInterestRate}
            label={intl.formatMessage(messages.currentInterestRate)}
            className='fullWidthInput'
          >
            <InputWithSpan
              action={setLiabilityCurrentInterestRate}
              value={workingLiability.currentInterestRate}
              spanValue='%'
              type='number'
              maxLength={9}
            />
          </Question>
          <Question
            {...questions.repaymentAmount}
            label={intl.formatMessage(messages.repaymentAmount)}
            className='fullWidthInput'
          >
            <CurrencyInputWithSelection
              action={setLiabilityRepaymentAmount}
              value={workingLiability.repaymentAmount}
              maxLength={11}
              selectionDefault={MONTHLY}
              selectionValue={workingLiability.repaymentFrequency}
              selectionAction={setLiabilityRepaymentFrequency}
              selectionItems={FREQUENCY_OPTIONS}
              disabled={workingLiability.isMultiple}
            />
          </Question>
          <Question
            {...questions.loanExpiryDate}
            label={intl.formatMessage(messages.loanExpiryDate)}
            className='fullWidthInput'
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Selection
                action={setLiabilityLoanExpiryDateYear}
                value={workingLiability && workingLiability.loanExpiryDateYear}
                items={yearsData}
              />
              <Selection
                action={setLiabilityLoanExpiryDateMonth}
                value={workingLiability && workingLiability.loanExpiryDateMonth}
                items={monthsData}
              />
            </div>
          </Question>
          <Question
            {...questions.isRefinance}
            label={intl.formatMessage(messages.isRefinance)}
            className='fullWidthInput'
            direction='row'
          >
            <YesNoButtonList
              action={this.onPropertyIsRefinanceClick}
              value={workingLiability.isRefinance}
              disabled={workingLiability.isMultiple}
            />
          </Question>
          <Question
            {...questions.isInterestTaxDeductible}
            label={intl.formatMessage(messages.isInterestTaxDeductible)}
            className='fullWidthInput'
            direction='row'
          >
            <YesNoButtonList
              action={setLiabilityIsInterestTaxDeductible}
              value={workingLiability.isInterestTaxDeductible}
              disabled={workingLiability.isMultiple}
            />
          </Question>
          <Question
            {...questions.generateRentalIncome}
            label={intl.formatMessage(messages.generateRentalIncome, {
              purchasing,
            })}
            className='fullWidthInput'
            direction='row'
          >
            <YesNoButtonList
              action={setApplyPropertyGenerateRentalIncome}
              value={working.generateRentalIncome}
            />
          </Question>
          <Question
            {...questions.rentalIncomeAmount}
            label={intl.formatMessage(messages.rentalIncomeAmount)}
            className='fullWidthInput'
          >
            <CurrencyInputWithSelection
              action={setIncomeValue}
              value={workingIncome.value}
              selectionDefault={WEEKLY}
              selectionValue={workingIncome.frequency}
              selectionAction={setIncomeFrequency}
              selectionItems={FREQUENCY_OPTIONS}
            />
          </Question>
          <Question
            {...questions.useAsSecurity}
            label={intl.formatMessage(messages.useAsSecurity)}
            className='fullWidthInput'
            direction='row'
          >
            <YesNoButtonList
              action={setApplyPropertyUseAsSecurity}
              value={working.useAsSecurity}
            />
          </Question>
          <Question {...questions.saveButton} className='fullWidthButton'>
            <Button
              theme='linkButton'
              hasErrorMessage={!formCompleted}
              onClick={this.onSubmit}
            >
              {intl.formatMessage(messages.save)}
            </Button>
          </Question>
          {working && working.id > 0 && (
            <Button
              className='brandColor__reverseButton'
              onClick={() => remove(working.id)}
            >
              {intl.formatMessage(messages.remove)}
            </Button>
          )}
          <input className='hidden' type='submit' />
        </fieldset>
      </Form>
    );
  }
}

export default injectIntl(
  manageQuestions(DisconnectedPropertyForm, propsTransformForQuestion),
);
