import { LIABILITY_CREDIT_CARD } from 'shared/constants/myCRMTypes/liabilities';
import { MYCRM_TO_MILLI_FREQ_ID } from 'shared/lib/frequencyHelper';
import { historyBuilderForMilli } from './history';

export function liabilityBuilderForMilli(liability) {
  return {
    id: liability.id,
    assetId: liability.asset_id,
    totalLoanAmount: liability.value,
    type: liability.type_id,
    clientIds: liability.client_ids ? liability.client_ids.sort() : undefined,
    category: liability.category_id,
    description: liability.description,
    remainingYears: liability.remaining_loan_term_years,
    remainingMonths: liability.remaining_loan_term_months,
    totalCardLimit: liability.limit,
    originalLoanAmount:
      liability.type !== LIABILITY_CREDIT_CARD.id ? liability.limit : undefined,
    currentInterestRate: liability.interest_rate,
    repaymentAmount: liability.repayments,
    repaymentFrequency:
      MYCRM_TO_MILLI_FREQ_ID[liability.repayment_frequency_id] || 12,
    loanExpiryDateMonth: liability.documented_loan_term_month,
    loanExpiryDateYear: liability.documented_loan_term_year,
    creditorId: liability.creditor_id,
    creditorName: liability.creditor_name,
    isInterestTaxDeductible: liability.interest_tax_deductible,
    isRefinance: liability.is_refinance,
    ...historyBuilderForMilli(liability),
  };
}
