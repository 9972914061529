import {
  DIRECT_OPTIONS_API_PATH,
  DIRECT_LOAN_STRUCTURE_API_PATH,
} from 'shared/constants/apiPaths';

import {
  axiosWithAuth as directAxiosWithAuth,
  returnJSON as directReturnJSON,
} from './mycrmBaseApi';

export function getObjectiveOptionsDirect() {
  return directAxiosWithAuth
    .get(`${DIRECT_OPTIONS_API_PATH}/objective-options`)
    .then(directReturnJSON);
}

export function getLoanAppQuestions(questionType) {
  return directAxiosWithAuth
    .get(
      `${DIRECT_OPTIONS_API_PATH}/LoanApplication/Question?questionType=${questionType}&showAllQuestionnaire=true`,
    )
    .then(directReturnJSON);
}

export function getAddressStreetTypes() {
  return directAxiosWithAuth
    .get(`${DIRECT_OPTIONS_API_PATH}/address/street-types`)
    .then(directReturnJSON)
    .then((data) => data && data.Data);
}

export function getDocumentTypesAU(questionType) {
  return directAxiosWithAuth
    .get(
      `${DIRECT_OPTIONS_API_PATH}/loan-application/supporting-document-types?onlyMilliDocuments=true`,
    )
    .then(directReturnJSON);
}

export function getDocumentTypesNZ() {
  return directAxiosWithAuth
    .get(
      `${DIRECT_OPTIONS_API_PATH}/LoanApplication/NZDocumentTypeGet?onlyMilliDocuments=true`,
    )
    .then(directReturnJSON);
}

export function getDepositTypesDirect() {
  return directAxiosWithAuth
    .get(
      `${DIRECT_OPTIONS_API_PATH}/LoanApplication/DepositType?lendingCategoryId=0&isMilli=true`,
    )
    .then(directReturnJSON);
}

export function getLoanTerms(isIncludeMonths = false) {
  return directAxiosWithAuth
    .get(
      `${DIRECT_OPTIONS_API_PATH}/loan/loanterms?isIncludeMonths=${isIncludeMonths}`,
    )
    .then(directReturnJSON);
}

export function getLoanTermMonthly() {
  return directAxiosWithAuth
    .get(`${DIRECT_LOAN_STRUCTURE_API_PATH}/loan-term-monthly`)
    .then(directReturnJSON);
}
